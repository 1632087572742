import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppFormItem, AppSelect, EmptyState, Loading, PageHeader } from '../../components';
import { LayoutContext } from '../../contexts/LayoutContext';
import { DatePicker, Dropdown, Form, Row, Tabs } from "antd";
import { ColaboratorSelect } from "../colaborator/components";
import { JobSelect } from "../job/components";
import { ModelPPCSelect } from "../model-ppc/components";
import { PpcEvaluationForm, PpcTaskQueryPage } from "./components";
import { useSearchParams } from "react-router-dom";
import { MonthAndYearHelper } from "../../helpers";
import TabPane from "antd/es/tabs/TabPane";
import { PPCMonthService } from "../../services";
import { MessageContext } from "../../contexts/MessageContext";
import { ApproveIcon, CreateIcon, DeleteIcon, DisapproveIcon, ManagementIcon, PrintIcon, SendIcon } from "../../components/Icons";
import { AuthContext } from "../../contexts/AuthContext";
import { ConfirmModalContext } from "../../contexts/ConfirmModalContext";
import { UserSelect } from "../user/components";
import { useNavigate } from 'react-router-dom';

const PpcFormPage = () => 
{
    const { setTitleAndSubTitle } = useContext(LayoutContext);   
    const [form] = Form.useForm();
    const [ searchParams ] = useSearchParams();
    const [ monthAndYear, setMonthAndYear ] = useState(null);
    const [ colaboratorId, setColaboratorId ] = useState(null);
    const [ activeTab, setActiveTab] = useState("1");
    const [ data, setData] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ menuProps, setMenuProps ] = useState([]);
    const { showError } = useContext(MessageContext);
    const { executeIfConfirm } = useContext(ConfirmModalContext);
    const { isAdmin } = useContext(AuthContext);
    const navigate= useNavigate();

    const loadData = async() => {
        try 
        {
            setLoading(true);   
            const { data } = await PPCMonthService.getByMonthAndYear(monthAndYear, colaboratorId);   
            setData(data.value);
            mapMenuProps(data.value.status);
            form.setFieldValue('jobId', data.value.job.id);
            form.setFieldValue('modelPPCId', data.value.modelPPC.id);
            form.setFieldValue('colaboratorId', data.value.colaborator.id);
            form.setFieldValue('status', data.value.status);            
            form.setFieldValue('approveUserId', data.value.approveUser?.id);
        } 
        catch(error) 
        {
            setData(null);
            mapMenuProps(null);
            form.setFieldValue('jobId', null);
            form.setFieldValue('modelPPCId', null);
            form.setFieldValue('colaboratorId', null);
            form.setFieldValue('status', null);
            form.setFieldValue('approveUserId', null);
            showError(error, 'Ocorreu um problema para carregar as avalições');
        }
        finally{
           setLoading(false)
        }
    }

    const mapMenuProps = (status) => {
        const items = [];
        if (status === 0)
        {
            items.push( {
                label: 'Enviar p/ Aprovação',
                key: 'send_to_approve',
                icon: <SendIcon />,
            });
            items.push( {
                label: 'Excluir PPC',
                key: 'delete',
                icon: <DeleteIcon />,
                danger: true,
            });
        }
        else if (status === 1 && isAdmin)
        {
            items.push( {
                label: 'Excluir PPC',
                key: 'delete',
                icon: <DeleteIcon />,
                danger: true,
            });
        } 
        else if (status === 2 && isAdmin)
        {
            items.push( {
                label: 'Aprovar',
                key: 'approve',
                icon: <ApproveIcon />,
            });
            items.push( {
                label: 'Reprovar',
                key: 'disapprove',
                icon: <DisapproveIcon />,
            });
            items.push( {
                label: 'Excluir PPC',
                key: 'delete',
                icon: <DeleteIcon />,
                danger: true,
            });
        }
        else if (status === 3)
        {
            items.push( {
                label: 'Enviar p/ Aprovação',
                key: 'send_to_approve',
                icon: <CreateIcon />,
            });

            if (isAdmin) 
            {
                items.push( {
                    label: 'Excluir PPC',
                    key: 'delete',
                    icon: <DeleteIcon />,
                    danger: true,
                });

            }
        }

        setMenuProps({
            items,
            onClick: ({key}) => 
                key === 'approve' ? approve() :
                key === 'disapprove' ? disapprove() :
                key === 'send_to_approve' ? sendToApprove() :
                deleteHandler()
        });
    }

    const approve = () => executeIfConfirm('Atenção', 'Tem certeza que deseja aprovar esse PPC ?', async () => 
    {
        try 
        {
            setLoading(true);   
            await PPCMonthService.approve({ monthAndYear, colaboratorId });   
            await loadData();
        } 
        catch(error) 
        {
            showError(error, 'Ocorreu um problema para aprovar o ppc');
        }
        finally{
           setLoading(false)
        }
    });

    const disapprove = () => executeIfConfirm('Atenção', 'Tem certeza que deseja reprovar esse PPC ?', async () => 
    {
        try 
        {
            setLoading(true);   
            await PPCMonthService.disapprove({ monthAndYear, colaboratorId });   
            await loadData();
        } 
        catch(error) 
        {
            showError(error, 'Ocorreu um problema para reprovar o ppc');
        }
        finally{
           setLoading(false)
        }
    });

    const sendToApprove = () => executeIfConfirm('Atenção', 'Tem certeza que deseja enviar para aprovação esse PPC ?', async () => 
    {
        try 
        {
            setLoading(true);   
            await PPCMonthService.sendToApprove({ monthAndYear, colaboratorId });   
            await loadData();
        } 
        catch(error) 
        {
            showError(error, 'Ocorreu um problema para enviar ppc para aprovação');
        }
        finally{
           setLoading(false)
        }
    });

    const deleteHandler = () => executeIfConfirm('Atenção', 'Tem certeza que deseja excluir esse PPC ?', async () => 
    {
        try 
        {
            setLoading(true);   
            await PPCMonthService.remove(monthAndYear, colaboratorId);  
            setData(null);
            navigate(-1);          
        } 
        catch(error) 
        {
            showError(error, 'Ocorreu um problema para enviar ppc para aprovação');
        }
        finally{
           setLoading(false)
        }
    });

    useEffect(() => {
        setTitleAndSubTitle('Gerenciamento do PPC', 'Gerenciamento do ppc do colaborador');        
    }, 
    []);

    useEffect(() =>{
        if (! monthAndYear && ! colaboratorId) 
        {
            setMonthAndYear(searchParams.get('monthAndYear'));
            setColaboratorId(searchParams.get('colaboratorId'));      
        }
        else if (monthAndYear && colaboratorId)
        {
            loadData();
        }        
    }, [monthAndYear]);
    
    return (
        <>
            { monthAndYear &&
              <>
                    <PageHeader>
                        <Dropdown
                            menu={menuProps}
                            placement="bottomLeft"
                            trigger={['click']}
                            arrow
                        >
                            <AppButton><ManagementIcon/>Gerenciar</AppButton>
                        </Dropdown>
                        <AppButton 
                            variant="outlined" 
                            onClick={() => window.open(`${window.location.origin}/report/ppc-month?params=${JSON.stringify({monthAndYear, colaboratorId})}`, '_blank') } >
                            <PrintIcon/>Imprimir
                        </AppButton>
                    </PageHeader>     
                    <Form 
                        form={form}
                        layout="vertical"
                        name="form_ppc"
                        initialValues={{
                            monthAndYear : MonthAndYearHelper.toDayJs(monthAndYear), 
                            colaboratorId : null,
                            modelPPCId : null,
                            jobId : null,
                            approveUserId : null, 
                            status :null
                        }}
                        >
                        <Row>
                            <AppFormItem
                                span={4}
                                name="monthAndYear"
                                label="Mês Ano"                                 
                                rules={[{ required: true, message: "Campo obrigatório" }]}
                                >
                                <DatePicker 
                                    allowClear={false}
                                    format="MM-YYYY" 
                                    size="small" 
                                    picker="month"
                                    onChange={(_, value) => setMonthAndYear(value)}
                                    style={{width: '100%'}}
                                    />
                            </AppFormItem>   
                            {
                                data &&
                                <AppFormItem
                                    span={4}
                                    name="status"
                                    label="Status"                                 
                                    >
                                    <AppSelect allowClear size="small" 
                                        placeholder="Selecione"
                                        disabled
                                        options={[  
                                        { value: 0, label: "Aberto" }, 
                                        { value: 1, label: "Aprovado" },
                                        { value: 2, label: "Aprovação Pendente" },                        
                                        { value: 3, label: "Reprovado" }]}></AppSelect>
                                </AppFormItem>  
                            }                   

                            { data &&                             
                                <AppFormItem
                                    span={16}
                                    name="approveUserId"
                                    label="Aprovado Por" 
                                    >
                                    <UserSelect 
                                    placeholder="PPC ainda não provado" 
                                    currentRecord={{ id : data?.approveUser?.id, name : data?.approveUser?.name }} disabled></UserSelect>
                                </AppFormItem>
                            }                                      
                        </Row>   

                        {
                            data &&                        
                            <Row>
                                <AppFormItem
                                    span={8}
                                    name="colaboratorId"
                                    label="Colaborador" 
                                    >
                                    <ColaboratorSelect currentRecord={{ id : data?.colaborator?.id, name : data?.colaborator?.name }} disabled></ColaboratorSelect>
                                </AppFormItem>
                                <AppFormItem
                                    span={8}
                                    name="jobId"
                                    label="Cargo" 
                                    >
                                    <JobSelect currentRecord={{ id : data?.job?.id, name : data?.job?.name }} disabled></JobSelect>
                                </AppFormItem>
                                <AppFormItem
                                    span={8}
                                    name="modelPPCId"
                                    label="Modelo de Avaliação" >
                                    <ModelPPCSelect currentRecord={{ id : data?.modelPPC?.id, name : data?.modelPPC?.name}} disabled></ModelPPCSelect>
                                </AppFormItem>    
                            </Row>
                        }
                    </Form>      
                    {
                        loading ? <Loading/> :
                        data ?
                        <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
                            <TabPane key="1" tab="Avaliações">                            
                                <PpcEvaluationForm 
                                    colaboratorId={colaboratorId}
                                    modelPPCId={data?.modelPPC?.id}
                                    monthAndYear={monthAndYear}>
                                </PpcEvaluationForm>
                            </TabPane>     
                            {
                               data?.job?.percentageTasks > 0 &&                                 
                               <TabPane key="2" tab="Plano de Curto Prazo">                            
                                    <PpcTaskQueryPage 
                                        colaboratorId={colaboratorId}
                                        monthAndYear={monthAndYear}>
                                    </PpcTaskQueryPage>
                                </TabPane> 
                            }     
                            <TabPane key="3" tab="Evidências">                            
                                <p>Aba em desenvolvimento</p>
                            </TabPane>  
                        </Tabs> :
                        <EmptyState/>
                    }      
              </>  
            }
        </>
    )
}

export default PpcFormPage;